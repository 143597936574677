<template>
    <a-row :gutter="50">
        <a-col 
            :sm="24" 
            :lg="isMobile ? 24 : 16">
            <template v-if="event">
                <div class="event_datetime"><i class="fi fi-rr-calendar-clock"></i> {{ eventDateTime }}</div>

                <Aside v-if="isMobile" :event="event" />

                <TextViewer v-if="event.description" :body="event.description" />
                <div 
                    v-else
                    class="gray mt-1"
                    :class="isMobile && 'py-2'">
                    Описание отсутствует
                </div>
                <!-- Комментарии -->
                <div class="mt-5">
                    <div class="mb-1 font-semibold">
                        Комментарии
                    </div>
                    <vue2CommentsComponent
                        bodySelector=".event_body_wrap"
                        :related_object="event.id"
                        model="events" />
                </div>
            </template>
            <a-skeleton
                v-else
                active
                :paragraph="{ rows: 5 }" />
        </a-col>
        <a-col
            v-if="!isMobile"
            class="mt-6 mb:mt-0 lg:mt-0"
            :sm="24"
            :lg="8">
            <div class="body_aside">
                <template v-if="event">
                    <Aside :event="event" />
                </template>
                <a-skeleton
                    v-else
                    active
                    :paragraph="{ rows: 2 }" />
            </div>
        </a-col>
    </a-row>
</template>

<script>
import TextViewer from '@apps/CKEditor/TextViewer.vue'
import vue2CommentsComponent from '@apps/vue2CommentsComponent'
import Aside from '../Aside.vue'
export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    components: {
        TextViewer,
        vue2CommentsComponent,
        Aside
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        eventDateTime() {
            if(this.event) {
                if(this.$moment(this.event.start_at).format('DD.MM.YYYY') === this.$moment(this.event.end_at).format('DD.MM.YYYY')) {
                    if(this.event.all_day) {
                        return `${this.$moment(this.event.start_at).format('dddd, DD MMMM')}, весь день`
                    } else {
                        return `${this.$moment(this.event.start_at).format('dddd, DD MMMM HH:mm')} - ${this.$moment(this.event.end_at).format('HH:mm')}`
                    }
                } else {
                    if(this.event.all_day) {
                        if(this.$moment(this.event.start_at).format('ddd, DD MMM') === this.$moment(this.event.end_at).add(-1, 'days').format('ddd, DD MMM')) {
                            return `${this.$moment(this.event.start_at).format('dddd, DD MMMM')}, весь день`
                        } else {
                            return `${this.$moment(this.event.start_at).format('ddd, DD MMM')} - ${this.$moment(this.event.end_at).add(-1, 'days').format('ddd, DD MMM')}`
                        }
                    } else {
                        return `${this.$moment(this.event.start_at).format('ddd, DD MMM HH:mm')} - ${this.$moment(this.event.end_at).format('ddd, DD MMM HH:mm')}`
                    }
                }
            } else 
                return null
        }
    }
}
</script>

<style lang="scss" scoped>
.event_datetime{
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 15px;
    color: #000;
    display: flex;
    align-items: center;
    i{
        margin-right: 8px;
        color: var(--gray);
    }
}
.body_aside{
    padding: 15px;
    background: #fafafa;
    border-radius: var(--borderRadius);
}
</style>