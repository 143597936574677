<template>
    <Files
        :sourceId="event.id"
        :isFounder="isAuthor"
        widgetEmbed
        isStudent />
</template>

<script>
import Files from '@apps/vue2Files'
import { mapState } from 'vuex'
export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    components: {
        Files
    },
    computed: {
        ...mapState({
            user: state => state.user.user
        }),
        isAuthor() {
            return this.user?.id === this.event?.author?.id
        }
    }
}
</script>