<template>
    <a-drawer
        title=""
        placement="right"
        class="event_drawer"
        :width="drawerWidth"
        destroyOnClose
        :zIndex="zIndex"
        :visible="visible"
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <div ref="drawerHeader" class="drawer_header flex items-center justify-between truncate">
            <div
                v-if="event"
                class="text-base font-semibold flex items-center truncate label">
                <div><div :style="`background: ${event.color};`" class="event_badge"></div></div> <div class="truncate">{{ event.name }}</div>
            </div>
            <a-skeleton
                v-else
                active
                :paragraph="{ rows: 1 }" />
            <div class="flex items-center pl-4">
                <template v-if="event">
                    <template v-if="actions">
                        <template v-if="actions.edit">
                            <a-button 
                                v-if="!event.is_finished"
                                type="success" 
                                ghost 
                                :icon="isMobile && 'fi-rr-check-double'"
                                flaticon
                                :loading="closeLoading"
                                class="mr-3"
                                @click="closeToggleEvent(true)">
                                <template v-if="!isMobile">Завершить</template>
                            </a-button>
                            <a-button 
                                v-if="event.is_finished"
                                type="success" 
                                ghost 
                                :icon="isMobile && 'fi-rr-rotate-right'"
                                flaticon
                                :loading="closeLoading"
                                class="mr-3 opacity-60"
                                @click="closeToggleEvent(false)">
                                <template v-if="!isMobile">Отметить активным</template>
                            </a-button>
                        </template>
                        <template v-else>
                            <a-button 
                                v-if="isMobile"
                                icon="fi-rr-remove-user"
                                flaticon
                                class="mr-2 text_red"
                                shape="circle"
                                type="ui" 
                                ghost
                                :loading="leaveLoader"
                                @click="leaveEvent()" />
                            <a-button 
                                v-else
                                icon="fi-rr-remove-user"
                                flaticon
                                class="mr-3"
                                type="danger" 
                                ghost
                                :loading="leaveLoader"
                                @click="leaveEvent()">
                                Покинуть событие
                            </a-button>
                        </template>
                    </template>
                    <a-dropdown 
                        :getPopupContainer="getPopupContainer" 
                        :trigger="['click']">
                        <a-button 
                            type="ui" 
                            ghost 
                            shape="circle"
                            :diabled="actions ? false : true"
                            :loading="actionLoading"
                            icon="fi-rr-menu-dots-vertical" 
                            flaticon />
                        <a-menu slot="overlay">
                            <template v-if="actions">
                                <a-menu-item key="share" class="flex items-center" @click="shareHandler()">
                                    <i class="fi fi-rr-share mr-2"></i>
                                    Поделиться
                                </a-menu-item>
                                <a-menu-item v-if="actions.edit" key="edit" class="flex items-center" @click="editHandler()">
                                    <i class="fi fi-rr-edit mr-2"></i>
                                    Редактировать
                                </a-menu-item>
                                <template v-if="actions.delete">
                                    <a-menu-divider />
                                    <a-menu-item key="delete" class="text-red-500 flex items-center" @click="deleteHanlder()">
                                        <i class="fi fi-rr-trash mr-2"></i> Удалить
                                    </a-menu-item>
                                </template>
                            </template>
                        </a-menu>
                    </a-dropdown>
                </template>
                <a-button
                    type="ui"
                    ghost
                    shape="circle"
                    class="ml-2 text-current"
                    icon="close"
                    @click="visible = false" />
            </div>
        </div>
        <div class="drawer_body event_body_wrap">
            <a-tabs 
                v-if="event"
                v-model="tabKey" 
                class="header_tab">
                <a-tab-pane key="info" tab="Событие" />
                <a-tab-pane key="files" tab="Файлы" />
            </a-tabs>
            <div class="tab_wrapper">
                <a-row v-if="!event" :gutter="50">
                    <a-col 
                        :sm="24" 
                        :lg="isMobile ? 24 : 16">
                        <a-skeleton
                            active
                            :paragraph="{ rows: 5 }" />
                    </a-col>
                    <a-col
                        v-if="!isMobile"
                        class="mt-6 mb:mt-0 lg:mt-0"
                        :sm="24"
                        :lg="8">
                        <div class="body_aside">
                            <a-skeleton
                                active
                                :paragraph="{ rows: 2 }" />
                        </div>
                    </a-col>
                </a-row>
                <a-tabs 
                    v-else 
                    :activeKey="tabKey" 
                    class="content_tab">
                    <a-tab-pane key="info" tab="">
                        <Event :event="event" />
                    </a-tab-pane>
                    <a-tab-pane key="files" tab="">
                        <Files :event="event" />
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
    </a-drawer>
</template>

<script>
import eventBus from '@/utils/eventBus'
import Files from './TabWidget/Files.vue'
import Event from './TabWidget/Event.vue'
export default {
    components: {
        Files,
        Event
    },
    computed: {
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            if(this.windowWidth > 1200)
                return 1200
            else {
                return '100%'
            }
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            actionLoading: false,
            closeLoading: false,
            event: null,
            actions: null,
            tabKey: 'info',
            leaveLoader: false,
            zIndex: 1000
        }
    },
    watch: {
        '$route.query'(val) {
            if(val.event) {
                this.openEventDrawer()
            }
        },
    },
    created: function() {
        if(this.$route.query.event)
            this.openEventDrawer()
    },
    methods: {
        leaveEvent() {
            this.$confirm({
                title: 'Вы действительно хотите покинуть событие?',
                content: '',
                okText: 'Удалить',
                okType: 'danger',
                zIndex: 2000,
                closable: true,
                cancelText: 'Закрыть',
                onOk: () => {
                    this.leaveLoader = true
                    return new Promise((resolve, reject) => {
                        this.$http.post(`/calendars/events/${this.event.id}/escape/`)
                            .then(() => {
                                this.visible = false
                                this.$message.success('Вы покинули событие')
                                eventBus.$emit('delete_event', this.event.id)
                                this.leaveLoader = false
                                resolve()
                            })
                            .catch(e => {
                                console.log(e)
                                this.leaveLoader = false
                                reject(e)
                            })
                    })
                }
            })
        },
        async closeToggleEvent(is_finished) {
            try {
                this.closeLoading = true
                const { data } = await this.$http.patch(`/calendars/events/${this.event.id}/`, {
                    is_finished
                })
                if(data) {
                    this.event.is_finished = is_finished
                    eventBus.$emit('edit_event', this.event)
                    if(is_finished) {
                        this.$message.info('Событие завершено')
                    } else {
                        this.$message.info('Событие активно')
                    }
                }
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
            } finally {
                this.closeLoading = false
            }
        },
        shareHandler() {
            this.$store.commit('share/SET_SHARE_PARAMS', {
                model: 'event',
                shareId: this.event.id,
                object: this.event,
                shareUrl: `${window.location.origin}/?event=${this.event.id}`,
                shareTitle: this.event.name
            })
        },
        deleteHanlder() {
            this.$confirm({
                title: 'Вы действительно хотите удалить событие?',
                content: '',
                okText: 'Удалить',
                okType: 'danger',
                zIndex: 2000,
                closable: true,
                cancelText: 'Закрыть',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', {
                            id: this.event.id,
                            is_active: false
                        })
                            .then(() => {
                                this.$message.success('Событие удалено')
                                eventBus.$emit('delete_event', this.event.id)
                                this.visible = false
                                resolve()
                            })
                            .catch(e => {
                                console.log(e)
                                reject(e)
                            })
                    })
                }
            })
        },
        editHandler() {
            this.visible = false
            eventBus.$emit('open_event_form', null, null, this.event)
        },
        async getActions() {
            try {
                this.actionLoading = true
                const { data } = await this.$http.get(`/calendars/events/${this.event.id}/action_info/`)
                if(data?.actions) {
                    this.actions = data.actions
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.actionLoading = false
            }
        },
        getPopupContainer() {
            return this.$refs.drawerHeader
        },
        afterVisibleChange(vis) {
            if(vis) {
                this.getFullEvent()
            } else {
                this.event = null
                this.actions = null
                this.tabKey = 'info'
                this.zIndex = 1000
                let query = Object.assign({}, this.$route.query)
                if(query.event) {
                    delete query.event
                    this.$router.push({query})
                }
            }
        },
        openEventDrawer() {
            const query = this.$route.query
            if(query?.task || query?.viewProject || query?.viewGroup) {
                this.zIndex = 1500
            }
            this.visible = true
        },
        async getFullEvent() {
            try {
                this.loading = true
                let { event } = Object.assign({}, this.$route.query)
                const { data } = await this.$http.get(`/calendars/events/${event}/`)
                if(data) {
                    this.event = data
                    this.getActions()
                }
            } catch(e) {
                console.log(e)
                this.$message.error('Событие не найдено или удалено')
                this.visible = false
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.event_drawer{
    &::v-deep{
        .ant-drawer-header-no-title{
            display: none;
        }
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .ant-drawer-body{
            padding: 0px;
            height: 100%;
        }
    }
    .drawer_body{
        height: calc(100% - 40px);
        .tab_wrapper{
            padding: 15px;
            overflow-y: auto;
            overflow-x: hidden;
            height: calc(100% - 41px);
        }
        .header_tab{
            &::v-deep{
                .ant-tabs-bar{
                    padding-left: 15px;
                    padding-right: 15px;
                    margin: 0px;
                    .ant-tabs-tab{
                        padding: 10px 16px;
                    }
                }
                .ant-tabs-content{
                    display: none;
                }
            }
        }
        .content_tab{
            &::v-deep{
                .ant-tabs-bar{
                    display: none;
                }
            }
        }
    }
    .drawer_header{
        border-bottom: 1px solid var(--border2);
        height: 40px;
        padding: 0 15px;
        .event_badge{
            height: 15px;
            width: 15px;
            border-radius: 50%;
            margin-right: 8px;
        }
        &::v-deep{
            .ant-skeleton-paragraph{
                display: none;
            }
            .ant-skeleton-content{
                .ant-skeleton-title{
                    width: 90%!important;
                    margin: 0px;
                    height: 20px;
                }
            }
        }
    }
}
</style>