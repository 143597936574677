<template>
    <div ref="cl_aside" :class="isMobile && 'mb-5'">
        <div v-if="event.is_finished" class="aside_item">
            <a-tag color="purple">
                Событие завершено
            </a-tag>
        </div>
        <div v-if="event.author" class="aside_item">
            <Profiler
                :user="event.author"
                initStatus
                :getPopupContainer="getPopupContainer"
                :subtitle="{ text: 'Организатор', class: 'text-xs gray' }" />
        </div>
        <div v-if="event.members && event.members.length" class="aside_item">
            <div
                v-for="us in event.members"
                :key="us.id"
                class="aside_item__user">
                <Profiler
                    :user="us"
                    initStatus
                    :getPopupContainer="getPopupContainer"
                    :subtitle="{ text: 'Участник', class: 'text-xs gray' }" />
            </div>
        </div>
        <div v-if="event.meeting_url" class="aside_item">
            <div class="label">
                Конференция
            </div>
            <div class="value flex items-center">
                <a-button
                    type="ui"
                    :size="isMobile ? 'default' : 'small'"
                    icon="fi-rr-link"
                    flaticon
                    @click="openMeeting()">
                    Открыть
                </a-button>
                <a-button
                    type="ui"
                    :size="isMobile ? 'default' : 'small'"
                    icon="fi-rr-copy-alt"
                    class="ml-1"
                    content="Скопировать ссылку"
                    v-tippy
                    flaticon
                    @click="copyMeeting()" />
            </div>
        </div>
        <div v-if="event.calendar" class="aside_item">
            <div class="label">
                Календарь
            </div>
            <div class="value flex items-center">
                <a-badge :color="event.calendar.color" /> {{ event.calendar.name }}
            </div>
        </div>
        <div v-if="event.address" class="aside_item">
            <div class="label">
                Место проведения
            </div>
            <div class="value">
                {{ event.address }}
            </div>
        </div>
        <div v-if="event.event_type" class="aside_item">
            <div class="label">
                Тип события
            </div>
            <div class="value">
                {{ event.event_type.name }}
            </div>
        </div>
        <div v-if="event.privacy" class="aside_item">
            <div class="label">
                Приватность
            </div>
            <div class="value">
                {{ event.privacy.name }}
            </div>
        </div>
        <div v-if="event.calendar && event.calendar.related_object" class="aside_item">
            <div class="label">
                Связанный объект
            </div>
            <div class="value">
                <a :href="event.calendar.related_object.frontend_route" target="_blank">
                    {{ event.calendar.related_object.name || event.calendar.related_object.title }}
                </a>
            </div>
        </div>
        <div v-if="event.notify_at" class="aside_item">
            <div class="label">
                Напоминание
            </div>
            <div class="value">
                {{ notifyMin }}
            </div>
        </div>
    </div>
</template>

<script>
import { declOfNum } from '@/utils/utils.js'
export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },
    computed: {
        notifyMin() {
            const notify = this.$moment(this.event.start_at).diff(this.event.notify_at, 'minutes')
            return `Напомнить за ${notify} ${declOfNum(notify, ['минуту', 'минут', 'минут'])}`
        },
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    methods: {
        getPopupContainer() {
            return this.$refs[`cl_aside`]
        },
        openMeeting() {
            window.open(this.event.meeting_url, "_blank")
        },
        copyMeeting() {
            try {
                navigator.clipboard.writeText(this.event.meeting_url)
                this.$message.success('Ссылка скопирована')
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.aside_item{
    &:not(:first-child){
        padding-top: 15px;
    }
    &:not(:last-child){
        border-bottom: 1px solid var(--borderColor);
        padding-bottom: 15px;
    }
    .label{
        margin-bottom: 0.25rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 600;
    }
    &__user{
        &:not(:last-child){
            margin-bottom: 8px;
        }
    }
}
</style>