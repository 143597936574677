<template>
    <div ref="fileCreateWrap">
        <template v-if="oneUpload && !createFounder">
            <a-upload
                name="upload"
                multiple
                class="w-full upload_wfull"
                :showUploadList="false"

                :withCredentials="true"
                :headers="{
                    'X-CSRFToken': $cookies.get('csrftoken'),
                    ...includeHeader
                }"
                :action="action"
                @change="uploadFileHandler">
                <a-button 
                    v-if="viewType === 'card'"
                    class="create_item_grid flex items-center justify-center w-full">
                    <div class="create_item_icon_grid mr-2">
                        <i class="fi fi-rr-plus"></i>
                    </div>
                    <span>Загрузить файл</span>
                </a-button>
                <a-button 
                    v-else
                    class="create_item_list flex items-center justify-center w-full">
                    <div class="create_item_icon_list mr-2">
                        <i class="fi fi-rr-plus"></i>
                    </div>
                    <span>Загрузить с устройства</span>
                </a-button>
            </a-upload>
        </template>
        <template v-else>
            <div class="flex justify-end items-center">
                <template v-if="!isMyFiles && !oneUpload">
                    <a-button
                        v-tippy="{
                            trigger: md ? '' : 'mouseenter focus',
                            touch: false,
                            content: 'Прикрепить файл' 
                        }"
                        class="mr-2"
                        @click="modalVisible.file = true">
                        <FileAttach
                            :showAttached="false"
                            :attachmentFiles="attachmentFiles"

                            :attachingRootId="rootId"
                            :attachingSourceId="sourceId">
                            <template v-slot:openButton>
                                <div class="text-center flex items-center">
                                    <i class="fi fi-rr-clip"></i>
                                    <template v-if="md">
                                        <span class="ml-2">Прикрепить файл</span>
                                    </template>
                                </div>
                            </template>
                        </FileAttach>
                    </a-button>
                </template>
                <template v-if="showDeviceUpload">
                    <a-button
                        v-tippy="{
                            trigger: md ? '' : 'mouseenter focus',
                            touch: false,
                            content: 'Загрузить с устройства' 
                        }"
                        class="mr-2"
                        @click="modalVisible.file = true">
                        <div 
                            class="text-center flex items-center">
                            <a-upload
                                name="upload"
                                multiple
                                :showUploadList="false"
                        
                                :withCredentials="true"
                                :headers="{
                                    'X-CSRFToken': $cookies.get('csrftoken')
                                }"
                                :action="action"
                                @change="uploadFileHandler">
                                <i class="fi fi-rr-file-upload"></i>
                                <template v-if="md">
                                    <span class="ml-2">Загрузить с устройства</span>
                                </template>
                            </a-upload>
                        </div>
                    </a-button>
                </template>
                <template v-if="rootId || sourceId">
                    <template v-if="createFounder">
                        <a-button
                            v-tippy="{
                                trigger: md ? '' : 'mouseenter focus',
                                touch: false,
                                content: 'Создать папку' 
                            }"
                            key="createFolder"
                            @click="modalVisible.folder = true"
                            class="flex items-center">
                            <i class="fi fi-rr-add-folder"></i>
                            <template v-if="md">
                                <span class="ml-2">Создать папку</span>
                            </template>
                        </a-button>
                    </template>
                </template>
            </div>
        </template>
        
        <a-modal 
            v-model="modalVisible.folder" 
            title="Создать папку"
            cancelText="Отмена" 
            :getContainer="getCreateContainer"
            @ok="confirmFolderUpload()"
            okText="Создать">
            <a-form-model
                class=""
                :label="$t('folder_name')"
                prop="folder_name">
                <a-form-model-item>
                    <a-input 
                        v-model="createFolderName" 
                        placeholder="Имя папки" />
                </a-form-model-item>
                <a-form-model-item>
                    <a-textarea 
                        v-model="createFolderDesc"
                        placeholder="Описание" 
                        :rows="4" />
                </a-form-model-item>

                
            </a-form-model>
        </a-modal>

        <template v-if="fileDragCreate">
            <a-upload-dragger
                v-show="showDragField" 
                name="upload"
                class="files_drag_field"

                :multiple="true"
                :showUploadList="false"
                :withCredentials="true"
                :headers="{
                    'X-CSRFToken': $cookies.get('csrftoken'),
                    ...includeHeader
                }"
                :action="action"
                @change="uploadFileHandler">
                <div class="text-center flex justify-center items-center">
                    <a-spin 
                        v-if="fileLoading"/>
                    <template v-else>
                        <a-icon type="file-add"  :style="{ fontSize: '54px', color: '#0e4682'} " />
                        <span class="text-xl" :style="`color:#0e4682`">Перетащите сюда файлы</span>
                    </template>       
                </div>
            </a-upload-dragger>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import TokenService from "@/config/TokenService"
import fileSourcesProps from '../mixins/fileSourcesProps'
import FileAttach from './FileAttach.vue'
export default {
    name: 'FileCreate',
    mixins: [fileSourcesProps],
    components: {
        FileAttach
    },
    props: {
        viewType: {
            type: String,
            required: true
        },
        isMyFiles: {
            type: Boolean,
            default: false
        },
        action: {
            type: String,
            default: `${process.env.VUE_APP_API_URL}/common/upload/`        
        },
        oneUpload: {
            type: Boolean,
            default: false
        },
        createFounder: {
            type: Boolean,
            default: true
        },
        mobileApp: {
            type: Boolean,
            default: false
        },
        fileDragCreate: {
            type: Boolean,
            default: true
        },
        getCreateContainer: {
            type: Function,
            default: () => document.body
        },
        showDeviceUpload: {
            type: Boolean,
            default: true
        },
        attachmentFiles: {
            type: Array,
            default: () => []
        },

    },
    data() {
        return {
            modalVisible: {
                file: false,
                folder: false
            },
            createFolderName: '',
            createFolderDesc: '',
            form: {
                uploadingFiles: []
            },
            fileLoading: false,
            showDragField: false,
            includeHeader: {},
            apiUrl: `${process.env.VUE_APP_API_URL}/common/upload/`,
            
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
            isMobile: state => state.isMobile
        }),
        isFolder() {
            const rootIds = ['trash', 'my_files', this.rootId]
            return !rootIds.includes(this.sourceId)
        },
        // Breakpoints
        md() {
            return this.windowWidth >= 768
        }
    },
    created() {
        if(this.mobileApp) {
            this.getHeaders()
        }
    },
    methods: {
        ...mapActions('files', [
            'uploadFiles', 
            'createFolder', 
            'createMyFolder',
            'moveMyFiles'
        ]),
        ...mapMutations('files', ['ADD_FILE']),
        async getHeaders() {
            try {
                const token = await TokenService.getAccessToken()
                if(token) {
                    this.includeHeader = {
                        "Authorization": `Bearer ${token}`
                    }
                }
            } catch(e) {
                console.log(e)
            }
        },
        async confirmFolderUpload() {
            try {
                let folderId = this.sourceId
                if(folderId === this.rootId || folderId === 'my_files')
                    folderId = null
                
                if(this.isMyFiles)
                    await this.createMyFolder({
                        folderName: this.createFolderName,
                        folderDesc: this.createFolderDesc,
                        folderId: folderId
                    })
                else
                    await this.createFolder({
                        folderName: this.createFolderName,
                        folderDesc: this.createFolderDesc,
                        rootId: this.rootId, 
                        folderId: folderId
                    })
                this.$message.success(`Папка успешно создана`);
            } catch(error) {
                this.$message.error(`Не удалось создать папку`);
            } finally {
                this.modalVisible.folder = false
            }
        },



        async uploadFileHandler(info) {
            const status = info.file.status;
            if (status === 'done') {
                if (!this.sourceId && !this.rootId) {
                    this.attachmentFiles.push(info.file.response[0])
                } else {
                    await this.confirmUpload(info.file.response[0])
                }
            } else if (status === 'error') {
                this.$message.error(`Не удалось загрузить файл "${info.file.name}"`);
            }
            this.showDragField = false
        },
        async confirmUpload(file) {
            try {
                this.fileLoading = true
                const folderId = this.isFolder ? this.sourceId : null
                if(!this.isMyFiles) {
                    await this.uploadFiles({
                        files: [file.id], 
                        rootId: this.rootId, 
                        folderId: folderId
                    })
                } else {
                    this.ADD_FILE({
                        data: file, 
                        key: 'my_files'
                    })
                    this.isFolder &&
                    await this.moveMyFiles({
                        from: 'my_files',
                        where: folderId,
                        files: [file],
                    })
                }
                this.$message.success(`Файл "${file.name}" успешно загружен.`);
                
            } catch(e) {
                console.log(e)
                this.$message.error(this.$t('chat.error'))
            } finally {
                this.fileLoading = false
            }   
        },

        dragOver(event) {
            event.stopPropagation()
            event.preventDefault()

            event.dataTransfer.dropEffect = 'copy'
            this.showDragField = true
        },
        dragLeave(event) {
            event.stopPropagation()
            event.preventDefault()
            if (event.clientX === 0 && event.clientY === 0)
                this.showDragField = false
        },
        dropComplete(event) {
            event.stopPropagation()
            event.preventDefault()

            // this.showDragField = false

            // const fileList = { target: { files: e.dataTransfer.files } };

            // this.handleFileUpload(fileList)
        },
        
    },

    mounted() {
        this.$nextTick(() => {
            window.addEventListener('dragover', event => this.dragOver(event))
            window.addEventListener('drop', event => this.dropComplete(event))
            window.addEventListener('dragleave', event => this.dragLeave(event))
        })  
    },
}
</script>

<style scoped lang="scss">
.create_item_grid {
    display: flex;
    
    width: 170px;
    height: 200px;

    padding: 20px;

    border: 2px dashed var(--borderColor);
    border-radius: var(--borderRadius);
    
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #f0f3f7;
    }

    cursor: pointer;
    .create_item_icon_grid {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 48px;
        height: 48px;
        
        background-color: var(--grayColor);
        border-radius: var(--borderRadius);
    }
}
.create_item_list {
    .create_item_icon_list {
        display: flex;
        justify-content: center;
        align-items: center;
        
        width: 20px;
        height: 20px;
    
        font-size: 10px;
    
        background-color: var(--grayColor);
        border-radius: 4px;
    
    }
}

.files_drag_field {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 80%;
    transform: translateX(-50%);
    height: 100%;
    max-height: 400px;

    background: #fff;
    border: 3px dashed var(--primaryColor);
    border-radius: 20px;
}
</style>

<style lang="scss">
.files_drag_field {
    .ant-upload-drag {
        border: none;
        border-radius: 20px;
    }
}
.file_create_menu {
    .ant-upload-select {
        display: block;
    }

}

.files_attaching_drawer {
    .ant-upload-select {
        display: block;
    }
}
</style>

<style lang="scss">
.upload_wfull{
    .ant-upload{
        width: 100%;
    }
}
</style>