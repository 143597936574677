<template>
    <div>
        <AddEvent />
        <EventDrawer />
    </div>
</template>

<script>
import store from "./store/index"
import AddEvent from './components/AddEvent.vue'
import EventDrawer from './components/EventDrawer'
export default {
    components: {
        AddEvent,
        EventDrawer
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        }
    },
    created() {
        if(!this.$store.hasModule('calendar')) {
            this.$store.registerModule("calendar", store)
            if(this.isMobile) {
                this.$store.commit('calendar/SET_START_ACTIVE_TYPE', localStorage.getItem('cType') || 'timeGridDay')
            }
        }
    }
}
</script>